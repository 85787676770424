@media screen and (min-width: 360px){
    .textPageMain{
        h1{
            font-size: 18px;
            text-align: center;
            margin-bottom: 30px;
        }
        h2{
            font-size: 16px;
            text-align: left;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        h3{
            font-size: 15px;
            text-align: left;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        .pageContent{
            text-align: justify;
        }
        li{
            margin-bottom: 10px;
        }
        a{
            color: var(--primary-color);
            transition: all 0.5s;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

@media screen and (min-width: 992px){
    .textPageMain{
        h1{
            font-size: 20px;
            margin-bottom: 50px;
        }
        h2{
            font-size: 18px;
            margin-top: 30px;
            margin-bottom: 15px;
        }
        h3{
            font-size: 16px;
        }
    }
}

